import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { RecipeDocument } from "db";
import { AnnotationDocument } from "db/collections";
import { useHotkeys } from "react-hotkeys-hook";
import { v4 as uuid } from "uuid";
import { produce } from "immer";
import { debounce } from "lodash-es";

import rangy from "../../../../utilities/rangy";
import { RangyHighlight } from "../../../../rangy";
import { getDb } from "../../../../db";
import { cn } from "../../../../utilities";
import { useTextSettings } from "../../../../TextSettingsProvider";
import { ContentEditable } from "../../../../components/ContentEditable";
import { updateRecipeInstructions } from "../../../../actions/recipe";

import { ANNOTATION_CONTAINER_ID, serialize, useAnnotations } from "./hooks";
import AnnotationContextMenu from "./components/AnnotationContextMenu";
import "./Instructions.css";

type InstructionsProps = {
  recipe: RecipeDocument;
  annotations: AnnotationDocument[];
};

export function Instructions({ recipe, annotations }: InstructionsProps) {
  const { settings } = useTextSettings();
  const instructions = recipe.instructions || [];

  const highlighterElement = useRef<HTMLDivElement>(null);
  const {
    annotate,
    annotateWithSelection,
    currentAnnotation,
    isHighlighted,
    removeAnnotation,
    removeCurrent,
    resetCurrentAnnotationElement,
    currentAnnotationElement,
  } = useAnnotations(highlighterElement, annotations);
  const onAnnotationCreated = useCallback(async (highlight: RangyHighlight) => {
    const db = await getDb();
    return await db.annotations.insert({
      id: uuid(),
      content: highlight.getText(),
      range: serialize(highlight),
      recipeId: recipe.id,
    });
  }, []);

  // focus indicator
  const refMap = useRef(new Map<string, HTMLElement>());
  const [indicatorPosition, setIndicatorPosition] = useState<{
    key: string | null | undefined;
    offsetY: number;
    height: number;
  }>({
    key: null,
    offsetY: 0,
    height: 0,
  });
  useEffect(() => {
    const instructions = Array.from(refMap.current);
    if (instructions.length > 0) {
      const [key, item] = Array.from(refMap.current)[0];
      setIndicatorPosition((currentValues) => {
        return {
          key,
          height: item?.clientHeight ?? currentValues.height,
          offsetY: item?.offsetTop ?? currentValues.offsetY,
        };
      });
    }
  }, []);

  const keyToRef = (key: string) =>
    useCallback(
      (node: HTMLElement | null) => {
        node ? refMap.current.set(key, node) : refMap.current.delete(key);
      },
      [key],
    );

  const focusInstruction = (key: string) =>
    useCallback(() => {
      const item = refMap.current.get(key);
      setIndicatorPosition((currentValues) => {
        return {
          key,
          height: item?.clientHeight ?? currentValues.height,
          offsetY: item?.offsetTop ?? currentValues.offsetY,
        };
      });
    }, [key]);

  useHotkeys("up", (keyboardEvent) => {
    keyboardEvent.preventDefault();

    resetCurrentAnnotationElement();

    const keys = Array.from(refMap.current.keys());
    let index = keys.findIndex((key) => key === indicatorPosition.key);
    if (index === -1) {
      index = 0;
    }
    // out of bounds check
    if (index - 1 < 0) {
      return;
    }
    const prevKey = keys[index - 1];
    const prevElement = refMap.current.get(prevKey);
    setIndicatorPosition((currentValues) => ({
      key: prevKey,
      height: prevElement?.clientHeight ?? currentValues.height,
      offsetY: prevElement?.offsetTop ?? currentValues.offsetY,
    }));
    prevElement?.scrollIntoView({
      block: "center",
      behavior: "smooth",
      inline: "nearest",
    });
  });
  useHotkeys("down", (keyboardEvent) => {
    keyboardEvent.preventDefault();

    resetCurrentAnnotationElement();

    const keys = Array.from(refMap.current.keys());
    let index = keys.findIndex((key) => key === indicatorPosition.key);
    if (index === -1) {
      index = 0;
    }
    // out of bounds check
    if (index + 1 >= keys.length) {
      return;
    }
    const nextKey = keys[index + 1];
    const nextElement = refMap.current.get(nextKey);
    setIndicatorPosition((currentValues) => ({
      key: nextKey,
      height: nextElement?.clientHeight ?? currentValues.height,
      offsetY: nextElement?.offsetTop ?? currentValues.offsetY,
    }));
    nextElement?.scrollIntoView({
      block: "center",
      behavior: "smooth",
      inline: "nearest",
    });
  });
  useHotkeys("h", async () => {
    if (!indicatorPosition.key) {
      return;
    }

    const element = refMap.current.get(indicatorPosition.key);
    if (!element) {
      return;
    }

    if (isHighlighted(element)) {
      await removeAnnotation(
        element,
        async (annotation: AnnotationDocument) => {
          await annotation.remove();
        },
      );
    } else {
      // TODO: export a simple annotateElement(element) function from useAnnotations hook instead of this garbage
      const range = rangy.createRange(
        document.getElementById(ANNOTATION_CONTAINER_ID)?.ownerDocument,
      );

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      range.selectNodeContents(element.firstChild!);
      const selection = rangy.getSelection(window);
      selection.setSingleRange(range);

      await annotate(onAnnotationCreated);
    }
  });

  const updateRecipeInstructionsDebounced = debounce(
    (newInstructions) => updateRecipeInstructions(recipe, newInstructions),
    500,
  );

  return (
    <div
      id={ANNOTATION_CONTAINER_ID}
      className={cn(
        "recipe-instructions font-serif text-lg leading-relaxed",
        settings.fontSize.current && `text-${settings.fontSize.current}`,
        settings.lineSpacing.current &&
          `leading-${settings.lineSpacing.current}`,
      )}
      onMouseDown={annotateWithSelection(onAnnotationCreated)}
      ref={highlighterElement}
    >
      <ol className="counter-reset-list list-none space-y-4 p-0">
        {instructions?.map((section, index) => [
          section.name && (
            <li
              key={`section-${index}`}
              className="font-semibold"
              ref={keyToRef(`section-${index}`)}
              onClick={focusInstruction(`section-${index}`)}
            >
              <ContentEditable
                value={section.name}
                onInput={(value) => {
                  const newInstructions = produce(instructions, (draft) => {
                    draft[index].name = value;
                  });
                  updateRecipeInstructionsDebounced(newInstructions);
                }}
              />
            </li>
          ),
          ...(section.steps || []).map((step, stepIndex) => (
            <li
              key={`section-${index}-step-${stepIndex}`}
              className={cn(
                "before:counter-increment-list relative text-lg ease-in-out before:absolute before:-left-20 before:top-0 before:-z-10 before:w-14 before:text-center before:text-xl before:font-bold before:text-black/10 before:transition-all before:duration-150 before:content-[counter(list-item)]",
                indicatorPosition.key ===
                  `section-${index}-step-${stepIndex}` && "before:text-4xl",
              )}
              ref={keyToRef(`section-${index}-step-${stepIndex}`)}
              onClick={focusInstruction(`section-${index}-step-${stepIndex}`)}
            >
              <ContentEditable
                value={step}
                onInput={(value) => {
                  const newInstructions = produce(instructions, (draft) => {
                    draft[index].steps![stepIndex] = value;
                  });
                  updateRecipeInstructionsDebounced(newInstructions);
                }}
              />
            </li>
          )),
        ])}
      </ol>
      {/* https://tailwindcss.com/docs/typography-plugin */}
      <div
        className="duration-250 absolute top-[0px] w-[3px] bg-gradient-to-bl from-[#f0a800] to-[#dd1e84] transition-transform"
        style={{
          transformOrigin: "top left",
          transform: `translateY(${indicatorPosition.offsetY}px) translateX(-20px) scaleY(${indicatorPosition.height})`,
          height: `1px`,
        }}
      />
      {currentAnnotationElement &&
        createPortal(
          <AnnotationContextMenu
            annotation={currentAnnotation}
            onSaveNote={async (text) => {
              if (!text) {
                return;
              }

              await currentAnnotation?.modify((annotation) => {
                annotation.note = text;
                return annotation;
              });
            }}
            onRemove={removeCurrent}
          />,
          currentAnnotationElement,
          "annotation-context-menu",
        )}
    </div>
  );
}
