import { v4 as uuid } from "uuid";
import normalizeUrl from "normalize-url";
import { RecipeDocument } from "db";

import { toast } from "../components/Toast";
import { getDb } from "../db";

export async function deleteRecipe(recipe: RecipeDocument) {
  try {
    await recipe.remove();
    toast({ title: "Recipe successfully deleted" });
  } catch (err) {
    toast({ title: "Recipe could not be deleted" });
    console.error("Recipe could not be deleted", err);
  }
}

export async function addRecipe(url: string, onSuccess?: () => void) {
  const db = await getDb();
  const normalizedUrl = normalizeUrl(url);

  try {
    await db.recipes.insert({
      id: uuid(),
      url: normalizedUrl,
    });
    toast({ title: "Recipe successfully added" });
    onSuccess?.();
  } catch (err) {
    toast({ title: "Recipe could not be added" });
    console.error("Recipe could not be added", err);
  }
}

export async function updateRecipeName(
  recipe: RecipeDocument,
  name: RecipeDocument["name"],
) {
  try {
    await recipe.incrementalPatch({
      name,
    });
  } catch (err) {
    toast({ title: "Recipe name could not be updated" });
    console.error("Recipe name could not be updated", err);
  }
}

export async function updateRecipeTags(
  recipe: RecipeDocument,
  tags: RecipeDocument["tags"],
) {
  try {
    await recipe.modify((recipe) => {
      recipe.tags = tags;
      return recipe;
    });
  } catch (err) {
    toast({ title: "Recipe tags could not be updated" });
    console.error("Recipe tags could not be updated", err);
  }
}

export async function updateRecipeMeals(
  recipe: RecipeDocument,
  meals: RecipeDocument["meals"],
) {
  try {
    await recipe.modify((recipe) => {
      recipe.meals = meals;
      return recipe;
    });
  } catch (err) {
    toast({ title: "Recipe meals could not be updated" });
    console.error("Recipe meals could not be updated", err);
  }
}

export async function updateRecipeCuisines(
  recipe: RecipeDocument,
  cuisines: RecipeDocument["cuisines"],
) {
  try {
    await recipe.modify((recipe) => {
      recipe.cuisines = cuisines;
      return recipe;
    });
  } catch (err) {
    toast({ title: "Recipe cuisines could not be updated" });
    console.error("Recipe cuisines could not be updated", err);
  }
}

export async function updateRecipeFoodTypes(
  recipe: RecipeDocument,
  foodTypes: RecipeDocument["foodTypes"],
) {
  try {
    await recipe.modify((recipe) => {
      recipe.foodTypes = foodTypes;
      return recipe;
    });
  } catch (err) {
    toast({ title: "Recipe food types could not be updated" });
    console.error("Recipe food types could not be updated", err);
  }
}

export async function updateRecipeDescription(
  recipe: RecipeDocument,
  description: RecipeDocument["description"],
) {
  try {
    await recipe.incrementalPatch({ description });
  } catch (err) {
    toast({ title: "Recipe description could not be updated" });
    console.error("Recipe description could not be updated", err);
  }
}

export async function updateRecipeNote(
  recipe: RecipeDocument,
  note: RecipeDocument["note"],
) {
  try {
    await recipe.incrementalPatch({ note });
  } catch (err) {
    toast({ title: "Recipe note could not be updated" });
    console.error("Recipe note could not be updated", err);
  }
}

export async function updateRecipeIngredients(
  recipe: RecipeDocument,
  ingredientGroups: RecipeDocument["ingredientGroups"],
) {
  try {
    await recipe.incrementalPatch({ ingredientGroups });
  } catch (err) {
    toast({ title: "Recipe ingredients could not be updated" });
    console.error("Recipe ingredients could not be updated", err);
  }
}

export async function updateRecipeInstructions(
  recipe: RecipeDocument,
  instructions: RecipeDocument["instructions"],
) {
  try {
    await recipe.incrementalPatch({ instructions });
  } catch (err) {
    toast({ title: "Recipe instructions could not be updated" });
    console.error("Recipe instructions could not be updated", err);
  }
}
