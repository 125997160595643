import * as React from "react";
import { Outlet, ScrollRestoration, type RouteObject } from "react-router-dom";

import { Home, loader as homeLoader } from "./pages/Home";
import { Account } from "./pages/Account";
import { NotFound } from "./pages/NotFound";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { Toaster } from "./components/Toast";
import { Navigation } from "./Navigation";
import { Collection, loader as collectionLoader } from "./pages/Collection";
import { Collections, loader as collectionsLoader } from "./pages/Collections";
import { TailwindIndicator } from "./utilities/TailwindIndicator";
import { TooltipProvider } from "./components/Tooltip";
import { Search, loader as searchLoader } from "./pages/Search";
import { Recipe, loader as recipeLoader } from "./pages/Recipe";
import { Library, loader as libraryLoader } from "./pages/Library";
import { CommandPalette } from "./patterns/CommandPalette";
import { appLoader } from "./utilities/user-data";
import { CommandPaletteProvider } from "./CommandPaletteProvider";
import { Error } from "./Error";
import { TextSettingsProvider } from "./TextSettingsProvider";
import { Fallback } from "./Fallback";

export const routes: RouteObject[] = [
  {
    element: <App />,
    loader: appLoader,
    errorElement: <Error />,
    id: "root",
    HydrateFallback: Fallback,
    children: [
      {
        path: "/",
        loader: homeLoader,
        element: <Home />,
      },
      {
        path: "library",
        loader: libraryLoader,
        element: <Library />,
      },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "search/:searchTerm?",
        loader: searchLoader,
        element: <Search />,
      },
      {
        path: "collections",
        loader: collectionsLoader,
        element: <Collections />,
      },
      {
        path: "collections/:id/edit?",
        loader: collectionLoader,
        element: <Collection />,
      },
      {
        path: "/recipes/:id",
        loader: recipeLoader,
        element: <Recipe />,
      },
      { path: "*", element: <NotFound /> },
    ],
  },
];

function App() {
  return (
    <>
      <ScrollRestoration />
      <CommandPaletteProvider>
        <Toaster />
        <TooltipProvider delayDuration={150}>
          <div className="grid min-h-screen grid-cols-[55px_minmax(0,_1fr)]">
            <header className="sticky top-0 z-50 h-screen self-start">
              <Navigation />
            </header>
            <main>
              <ErrorBoundary fallback={<>Sorry</>}>
                <TextSettingsProvider>
                  <Outlet />
                </TextSettingsProvider>
              </ErrorBoundary>
            </main>
          </div>
          <CommandPalette />
          <TailwindIndicator />
        </TooltipProvider>
      </CommandPaletteProvider>
    </>
  );
}

export default App;
