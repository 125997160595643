import { addRxPlugin, createRxDatabase, RxDatabase, RxStorage } from "rxdb";
import { RxDBDevModePlugin } from "rxdb/plugins/dev-mode";
import { RxDBLeaderElectionPlugin } from "rxdb/plugins/leader-election";
import { RxDBMigrationPlugin } from "rxdb/plugins/migration-schema";
import { Consumer, createConsumer } from "@rails/actioncable";
import { RxReplicationState } from "rxdb/plugins/replication";
import { wrappedValidateAjvStorage } from "rxdb/plugins/validate-ajv";

import {
  Annotation,
  AnnotationCollection,
  Recipe,
  RecipeCollection,
  RecipeEvent,
  RecipeEventCollection,
  User,
  UserCollection,
  View,
  ViewCollection,
} from "./collections";
import { Deferred } from "./deferred";
import { DatabaseConfig } from "./types";
import { replicate } from "./replication";

type Collections = {
  annotations: AnnotationCollection;
  views: ViewCollection;
  recipes: RecipeCollection;
  "recipe-events": RecipeEventCollection;
  users: UserCollection;
};

export type Database = RxDatabase<Collections>;

const definitions = {
  annotations: Annotation,
  views: View,
  recipes: Recipe,
  "recipe-events": RecipeEvent,
  users: User,
};

let database: Deferred<Database>;
export let consumer: Deferred<Consumer>;
export let config: Deferred<DatabaseConfig>;

const replicationStates: RxReplicationState<any, any>[] = [];

export async function createDatabase(
  storage: RxStorage<any, any>,
  databaseConfig: DatabaseConfig,
): Promise<Database> {
  if (database) {
    return database;
  }

  database = new Deferred();
  consumer = new Deferred();

  // HACK: make createConsumer work by mocking some objects and functions on
  //  globalThis (aka window)
  if (databaseConfig.env === "react-native") {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    globalThis.addEventListener = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    globalThis.removeEventListener = () => {};
    globalThis.document = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      head: {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        querySelector: () => {},
      },
    };
  }

  // extend ws URL with authentication
  const accessToken =
    databaseConfig.auth?.currentToken?.accessToken?.accessToken;
  const websocketUrl = accessToken
    ? `${databaseConfig.api.ws}?access_token=${accessToken}`
    : databaseConfig.api.ws;

  const actionCableConsumer = createConsumer(websocketUrl);
  // patch consumer to allow token refresh
  Object.defineProperty(actionCableConsumer, "url", {
    get: function () {
      return `${databaseConfig.api.ws}?access_token=${accessToken}`;
    },
  });
  consumer.resolve(actionCableConsumer);

  config = new Deferred();
  config.resolve(databaseConfig);

  if (databaseConfig.mode === "development") {
    addRxPlugin(RxDBDevModePlugin);
  }
  addRxPlugin(RxDBLeaderElectionPlugin);
  addRxPlugin(RxDBMigrationPlugin);

  const multiInstance = false;

  const wrappedStorage =
    databaseConfig.mode === "development"
      ? wrappedValidateAjvStorage({ storage })
      : storage;

  const db = await createRxDatabase<Collections>({
    name: "nomnom",
    storage: wrappedStorage,
    multiInstance,
    eventReduce: true,
    ...databaseConfig.options,
  });

  try {
    const createdCollections = await db.addCollections(definitions);
    for (const [key, definition] of Object.entries(definitions)) {
      const collectionName: keyof typeof definitions = key as any;
      const collection = createdCollections[collectionName];
      if (definition.config.replicate) {
        const state = await replicate(
          databaseConfig,
          actionCableConsumer,
          collection,
        );
        replicationStates.push(state);
      }
      if (definition.config.setup) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await definition.config.setup(collection);
      }
    }
  } catch (err) {
    // when this fails, we have messed up the database
    await db.remove();
    database?.reject(new Error("broken schema"));
    return database;
  }
  await Promise.all(
    replicationStates.map((state) => state.awaitInitialReplication()),
  );

  // mark database as initialized
  database?.resolve(db);

  return database;
}

export async function destroyDatabase(): Promise<void> {
  if (!database) {
    return;
  }

  const db = await database;
  await db.remove();
}
