import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxCollection,
  RxDocument,
  RxCollectionCreator,
} from "rxdb";

import { CollectionConfig } from "../types";

const jsonSchema = {
  title: "User Schema",
  description: "A schema describing a nomnom user",
  version: 0,
  primaryKey: "id",
  type: "object",
  properties: {
    allowNotifications: {
      type: "boolean",
    },
    billingPlans: {
      type: "array",
      final: true,
      items: {
        type: "object",
        properties: {
          id: {
            type: "string",
            final: true,
          },
          description: {
            type: "string",
          },
          interval: {
            type: "string",
          },
          name: {
            type: "string",
          },
          priceCents: {
            type: "number",
          },
          stripePriceId: {
            type: "string",
          },
        },
      },
    },
    billingSubscription: {
      type: "object",
      properties: {
        active: {
          type: "boolean",
        },
        cancelAt: {
          type: ["string", "null"],
        },
        currentPeriodEnd: {
          type: "string",
        },
        currentPeriodStart: {
          type: "string",
        },
        id: {
          type: "string",
          final: true,
        },
        plan: {
          type: "object",
          properties: {
            description: {
              type: "string",
            },
            id: {
              type: "string",
              final: true,
            },
            interval: {
              type: "string",
            },
            name: {
              type: "string",
            },
            priceCents: {
              type: "number",
            },
            stripePriceId: {
              type: "string",
            },
          },
        },
        stripeId: {
          type: "string",
        },
      },
    },
    createdAt: {
      type: "string",
      final: true,
    },
    id: {
      type: "string",
      maxLength: 128,
      final: true,
    },
    email: {
      type: "string",
      final: true,
    },
    firstName: {
      type: "string",
    },
    lastName: {
      type: "string",
    },
    profileImageUrl: {
      type: "string",
    },
    web: {
      type: "object",
      properties: {
        textSettings: {
          type: "object",
          properties: {
            fontSize: { type: "string" },
            lineSpacing: { type: "string" },
          },
        },
      },
    },
  },
  required: ["id", "email", "allowNotifications", "billingPlans"],
} as const;
const typedSchema = toTypedRxJsonSchema(jsonSchema);

type UserType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof typedSchema>;

export type UserDocument = RxDocument<UserType>;
export type UserCollection = RxCollection<UserType>;

const schema: RxJsonSchema<UserType> = jsonSchema;

export const User: RxCollectionCreator<UserType> & {
  config: CollectionConfig<UserType>;
} = {
  config: {
    replicate: true,
  },
  schema,
};
